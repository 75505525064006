import React, { useState } from "react";
import { Button, Button as MUIButton, Stack } from "@mui/material";
import {
  Show,
  Tab,
  TabbedShowLayout,
  useRecordContext,
  useRefresh,
} from "react-admin";
import {Link} from "@mui/material";
import ResourceSelectDialog from "../Common/ResourceSelectDialog";
import UpdateCommentInTrialDialog from "../Common/UpdateCommentInTrialDialog";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import OneMealServiceMetaShow from "./OneMealServiceMetaShow";
import { setCustomSelectOpen } from "../../store/reducers/customSelect";
import EventBus from "../../EventBus";
import { EventBusEventsEnum } from "../../enums/EventBusEventsEnum";
import { PaymentStatusEnum } from "../../enums/PaymentStatusEnum";
import { OneMealServiceStatus } from "../../enums/OneMealServiceStatus";
import { CustomSnackbarTypes } from "../Common/CustomSnackBar";
import LocalityList from "../Locality/LocalityList";
import { OneMealService } from "../../services/OneMealService";
import TrialsCancelDialog from "../Common/TrialsCancelDialog";
import RescheduleOneMealDialog from "../Common/RescheduleOneMealDialog";
import ApproveTrialsDialog from "../Common/ApproveCookToTrials";
import { PaymentLinkDailog } from "../Common/PaymentLinksDailogs";
import AdminUserList from "../AdminUser/AdminUserList";
import GroupPermissionFunction from "../../utils/groupPermissionFunction";
import { UserGroupsEnum } from "../../enums/UserGroupsEnum";
import OneMealServiceAllocationTab from "./OneMealServiceAllocationTab";
import AdminNotesForOneMeal from "../Common/AdminNotesForOneMeal";
import TrialsRefundDialog from "../Common/TrialsRefundDialog";
import OneMealVendorPayoutTab from "./OneMealVendorPayoutTab";
import AssignCookOnOneMealDialog from "../Common/AssignCookOnOneMealDialog";
import OneMealHistoryTab from "./OneMealHistoryTab";
import { OneMeal } from "../../interfaces/OneMeal/OneMeal";
import { moduleType } from "../../enums/common";
import CookDenyDialog from "../Common/CookDenyDialog";
import ChangeOmsTimeDialog from "../Common/ChangeOmsTimeDialog";
import OmsDeniedCookList from "./OmsDeniedCookList";
import RequestCookConveyanceDialog from "../Common/RequestCookConveyanceDialog";
import UpdateOMSStatusDialog from "../Common/UpdateOMSStatusDialog";
import UpdateOMSPaymentStatusDialog from "../Common/UpdateOMSPaymentStatusDialog";
import { CallMaskingReferenceModelNameEnum } from "../../enums/CallMaskingReferenceModelNameEnum";
import CustomerCallRecordingsTab from "../Customers/CustomerCallRecordingsTab";
import CookServeList from "../Common/CookServeList";

const oneMealService = new OneMealService();

interface InputProps {
  name: any;
  callback: (data: any) => void;
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}

const CustomButton = (props: InputProps) => {
  let record = useRecordContext();
  
  return (
    <MUIButton
      onClick={() => {
        props.callback(record);
      }}
      size={"small"}
      color={props.color || "secondary"}
      variant={"contained"}
      style={{ width: "max-content", fontSize: "12px" }}
    >
      {props.name}
    </MUIButton>
  );
};

export const ActionButtons = (props: any) => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const userDetails = useAppSelector((state) => state.userDetails.value);
  let groupsArray: string[] = userDetails.adminUser.accessGroups;
  const dispatch = useAppDispatch();

  const handleFixSlcOpen = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const onClickSendPaymentLink = () => {
    EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
      message: "Are you sure you want to send the payment link?",
      onClose: (confirmed: boolean) => {
        if (confirmed) {
          oneMealService
            .sendOneMealPaymentLink(parseInt((record as any)?.id))
            .then((res) => {
              props.setPaymentDialog(true);
              props.setPaymentLinks(res);
              EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                message: "Payment link sent",
                type: CustomSnackbarTypes.SUCCESS,
              });
            });
        }
      },
      title: "Confirmation",
    });
  };

  return (
    <div>
      <Stack direction={"row"} style={{ marginBottom: "10px" }} gap={2}>
        {[
          OneMealServiceStatus.PENDING,
          OneMealServiceStatus.COOK_ALLOCATED,
          OneMealServiceStatus.COOK_CONFIRMED,
        ].includes(record?.status) && (
            <CustomButton
              name={record?.cookId ? "Change Cook" : "Assign Cook"}
              color={"primary"}
              callback={(data) => {
                props.setSelectedData(data);
                props.setOpenAssignCookDialog(true);
                props.setInitiatedBy(2);
              }}
            />
          )}
          {
            (record?.status !== OneMealServiceStatus.FULFILLED) && (
              <CustomButton
              name={"Change OMS Time"}
              color="primary"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setOneMealTimeUpdateDialog(true);
              }}
            />
            )
          }
        {[
          OneMealServiceStatus.PENDING,
          OneMealServiceStatus.COOK_ALLOCATED,
          OneMealServiceStatus.COOK_CONFIRMED,
          OneMealServiceStatus.ONGOING,
        ].includes(record?.status) && (
          <CustomButton
            name={"Cancel One Meal"}
            color="error"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setCancelTrialModel(true);
              props.setInitiatedBy(2);
            }}
          />
        )}
        {[
          OneMealServiceStatus.PENDING,
          OneMealServiceStatus.COOK_ALLOCATED,
          OneMealServiceStatus.COOK_CONFIRMED,
        ].includes(record?.status) &&
          !record?.userLeaveMarkedId && (
            <CustomButton
              name={"Reschedule One Meal"}
              color="primary"
              callback={(data: any) => {
                props.setSelectedData(data);
                props.setRescheduleModel(true);
              }}
            />
          )}
        {[OneMealServiceStatus.COOK_ALLOCATED].includes(record?.status) && (
          <CustomButton
            name={"Action By Cook"}
            color="success"
            callback={(data: any) => {
              props.setSelectedData(data);
              props.setApprovalModel(true);
              props.setInitiatedBy(2);
            }}
          />
        )}
        {[OneMealServiceStatus.COOK_CONFIRMED].includes(record?.status) && (
          <CustomButton
            name={"Mark as Ongoing"}
            color="primary"
            callback={(data: any) => {
              oneMealService
                .markAsOngoingForOneMeal(parseInt((record as any)?.id))
                .then((res) => {
                  EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                    message: "Success",
                    type: CustomSnackbarTypes.SUCCESS,
                  });
                  refresh();
                });
            }}
          />
        )}
        {[
          OneMealServiceStatus.COOK_CONFIRMED,
          OneMealServiceStatus.ONGOING,
        ].includes(record?.status) && (
          <CustomButton
            name={"Mark as Fulfilled"}
            color="primary"
            callback={() => {
              EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                message: "Are you sure you want to mark the meal fulfilled?",
                onClose: (confirmed: boolean) => {
                  if (confirmed) {
                    oneMealService
                      .markAsFulfiledForOneMeal(parseInt((record as any)?.id))
                      .then((res) => {
                        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                          message: "One Meal marked fulfilled",
                          type: CustomSnackbarTypes.SUCCESS,
                        });
                        refresh();
                      });
                  }
                },
                title: "Confirmation",
              });
            }}
          />
        )}
        <CustomButton
          name={"Add Admin Notes"}
          color="primary"
          callback={(data: any) => {
            props.setSelectedData(data);
            props.setOpenSetAdminNotesDialog(true);
          }}
        />
        <CustomButton
          name={"Update Meal Owner"}
          color="primary"
          callback={(data: any) => {
            props.setOpenUpdateMealOwner(true);
            props.getData(record);
            props.setIsForOneMealOwner(true);
            props.handleClickOpen();
          }}
        />
        {GroupPermissionFunction(groupsArray, [
          UserGroupsEnum.ADMIN,
          UserGroupsEnum.MANAGER,
        ]) && (
          <CustomButton
            name={"Update Meal Allocator"}
            color="primary"
            callback={(data: any) => {
              props.setOpenUpdateOneMealAllocator(true);
              props.getData(record);
              props.setIsForOneMealOwner(false);
              props.handleClickOpen();
            }}
          />
        )}
        {record?.areaId === null && (
          <CustomButton
            name={"Fix SLC"}
            color="primary"
            callback={(data: any) => {
              handleFixSlcOpen();
              props.setOpenFixSLC(true);
            }}
          />
        )}
        {record?.paymentStatus === PaymentStatusEnum.PAID && (
          <CustomButton
            name={"Refund Meal Amount"}
            color="error"
            callback={(data: any) => {
              props.setInitiatedBy(2);
              props.setSelectedData(data);
              props.setRefundOneMealModel(true);
            }}
          />
        )}
        {![OneMealServiceStatus.CANCELLED].includes(record?.status) &&
          record?.paymentStatus === PaymentStatusEnum.NOT_PAID && (
            <Button
              color="primary"
              variant={"contained"}
              onClick={onClickSendPaymentLink}
            >
              {"Send Payment Link"}
            </Button>
          )}
        {[OneMealServiceStatus.COOK_CONFIRMED].includes(record?.status) && (
          <CustomButton
            name={"Cook Deny"}
            color={"error"}
            callback={(data) => {
              props.setSelectedData(data);
              props.setOpenCookDenyDialog(true);
            }}
          />
        )}
          {[OneMealServiceStatus.FULFILLED].includes(record?.status) &&
            <Button
              color="primary"
              variant={"contained"}
              onClick={() => {
                  EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                      message: "Are you sure you want to penalize?",
                      onClose: (confirmed: boolean) => {
                          if (confirmed) {
                              oneMealService
                                  .penalizeCookForNotWearingTShirt(parseInt((record as any)?.id))
                                  .then((res) => {
                                      EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                                          message: "Success",
                                          type: CustomSnackbarTypes.SUCCESS,
                                      });
                                      refresh();
                                  });
                          }
                      },
                  });
              }}
            >
                {"Penalize for no t-shirt"}
            </Button>
          }
      </Stack>
      <Stack direction={"row"} style={{ marginBottom: "10px" }} gap={2}>
        {(record?.hasOptedPayAfterService || [
          PaymentStatusEnum.PAID,
          PaymentStatusEnum.PAYMENT_IN_PROGRESS,
          PaymentStatusEnum.REFUND_IN_PROGRESS,
          PaymentStatusEnum.REFUNDED,
          PaymentStatusEnum.REFUND_FAILED,
        ].includes(record?.paymentStatus)) && (
            <>
              <Link href={`#/VisitTracker?visitId=${record?.id}&date=${record?.date}&status=${JSON.stringify([record?.status])}`} target={'_blank'}>
                <CustomButton
                    name={"View Tracker"}
                    color="primary"
                    callback={() => {
                    }}
                />
              </Link>
              {record?.isConveyanceAllowed &&
                <CustomButton
                  name={"Request Cook Conveyance"}
                  color="primary"
                  callback={(data) => {
                    props.setSelectedData(data);
                    props.setRequestCookConveyanceDialog(true);
                  }}
                />
              }
              <CustomButton
                  name={"Support Comments"}
                  color="primary"
                  callback={() => {
                    props.setUpdateCommentDialog(true);
                    props.setInitiatedBy(5);
                  }}
              />
              <CustomButton
                  name={"CX Feedback"}
                  color="primary"
                  callback={() => {
                    props.setUpdateCommentDialog(true);
                    props.setInitiatedBy(6);
                  }}
              />
              <CustomButton
                  name={"Chef Feedback"}
                  color="primary"
                  callback={() => {
                    props.setUpdateCommentDialog(true);
                    props.setInitiatedBy(7);
                  }}
              />
              <CustomButton
                  name={"Allocation Comments"}
                  color="primary"
                  callback={() => {
                    props.setUpdateCommentDialog(true);
                    props.setInitiatedBy(8);
                  }}
              />
              {(record?.status !== OneMealServiceStatus.CANCELLED&&record?.status !== OneMealServiceStatus.FULFILLED&&!record?.preCheckedInAt)&&<CustomButton
                  name={"Pre Checkin"}
                  color="primary"
                  callback={() => {
                    EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                      message: "Confirm Pre Checkin",
                      onClose: (confirmed: boolean) => {
                        oneMealService.preCheckIn(Number(record?.cookId),Number(record?.id)).then((data)=>{
                          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Pre Checkin Successful",
                            type: CustomSnackbarTypes.SUCCESS,
                          });
                          refresh();
                        }).catch(error=>{
                          console.log(error); 
                        })
                          
                      },
                  });
                    
                  }}
                />

              }

              {
          !record?.isCashCollected
                &&record?.paymentStatus!=="PAID"&&
                <CustomButton
                  name={"Cash Collected"}
                  color="primary"
                  callback={() => {
                    EventBus.emitEvent(EventBusEventsEnum.SHOW_CONFIRMATION_DIALOG, {
                      message: "Confirm Cash Collected",
                      onClose: (confirmed: boolean) => {
                        oneMealService.cashCollected(Number(record?.id)).then((data)=>{
                          EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
                            message: "Cash collected successfully",
                            type: CustomSnackbarTypes.SUCCESS,
                          });
                          refresh();
                        }).catch(error=>{
                          console.log(error); 
                        })
                          
                      },
                  });
                    
                  }}
                />
          }
            </>
            
        )}
      </Stack>
      <Stack direction={"row"} style={{ marginBottom: "10px" }} gap={2}>
        {GroupPermissionFunction(groupsArray, [
                UserGroupsEnum.ADMIN,
              ]) && (
                <CustomButton
                  name={"Update OMS Status"}
                  color="primary"
                  callback={() => {
                    props.setUpdateOMSStatusDialog(true);
                  }}
                />
              )}
              {GroupPermissionFunction(groupsArray, [
                UserGroupsEnum.ADMIN,
              ]) && (
                <CustomButton
                  name={"Update OMS Payment Status"}
                  color="primary"
                  callback={() => {
                    props.setUpdateOMSPaymentStatusDialog(true);
                  }}
                />
              )}
      </Stack>
    </div>
  );
};

const OneMealServiceTabs = () => {
  let location = useLocation();
  let oneMealServiceId = location.pathname.split("/")[2];
  const recode: OneMeal = useRecordContext();
  

  return (
    <TabbedShowLayout syncWithLocation={false}>
      <Tab label={"Details"} key={"Details"}>
        <OneMealServiceMetaShow />
      </Tab>
      <Tab label={"Cook Serve List"} key={"Cook Serve List"}>
        <CookServeList serviceId={1} referenceId={recode?.areaId} time={recode?.time} totalPeople={recode?.totalPeople} date={recode?.date} hasService={true} mealServiceId={Number(oneMealServiceId)} houseId={recode?.houseId} type={"OMS"}/>
      </Tab>
      <Tab label={"Allocation Tab"} key={"Allocation Tab"}>
        <OneMealServiceAllocationTab
          oneMealServiceId={Number(oneMealServiceId)}
        />
      </Tab>
      <Tab label={"Vendor Payout Tab"} key={"Vendor Payout Tab"}>
        <OneMealVendorPayoutTab oneMealServiceId={Number(oneMealServiceId)} />
      </Tab>
      <Tab label={"History Tab"} key={"History Tab"}>
        <OneMealHistoryTab houseId={Number(recode?.house?.id)} />
      </Tab>
      <Tab label={"Denied Cooks"} key={"Denied Cooks"}>
        <OmsDeniedCookList serviceId={Number(oneMealServiceId)} />
      </Tab>
      <Tab label={"Call Recordings"} key={"Call Recordings"}>
        <CustomerCallRecordingsTab serviceId={Number(recode?.id)} referenceModalName={CallMaskingReferenceModelNameEnum.ONE_MEAL_SERVICE}/>
      </Tab>
    </TabbedShowLayout>
  );
};

const OneMealServiceShow = (props: any) => {
  const refresh = useRefresh();
  const dispatch = useAppDispatch();
  let [selectedData, setSelectedData] = useState<any>(null);
  const [openFixSLC, setOpenFixSLC] = useState(false);
  const [updateCommentDialog, setUpdateCommentDialog] = useState(false);
  let [rescheduleModel, setRescheduleModel] = useState<boolean>(false);
  let [cancelTrialModel, setCancelTrialModel] = useState<boolean>(false);
  const [openAssignCookDialog, setOpenAssignCookDialog] = useState(false);
  const [initiatedBy, setInitiatedBy] = useState<any>();
  let [approvalModel, setApprovalModel] = useState<boolean>(false);
  let [paymentDialog, setPaymentDialog] = useState<boolean>(false);
  let [paymentLinks, setPaymentLinks] = useState();
  const [openSetAdminNotesDialog, setOpenSetAdminNotesDialog] = useState(false);
  let [isForOneMealOwner, setIsForOneMealOwner] = useState<boolean>(false);
  const [openUpdateMealOwner, setOpenUpdateMealOwner] = useState(false);
  let [refundOneMealModel, setRefundOneMealModel] = useState<boolean>(false);
  const [openCookDenyDialog, setOpenCookDenyDialog] = useState<boolean>(false);
  const [openUpdateOneMealAllocator, setOpenUpdateOneMealAllocator] =
    useState(false);
  const [oneMealTimeUpdateDialog, setOneMealTimeUpdateDialog] = useState<boolean>(false);
  const [oneMealId, setOneMealId] = useState<any>();
  const [requestCookConveyanceDialog, setRequestCookConveyanceDialog] = useState<boolean>(false);
  const [updateOMSStatusDialog, setUpdateOMSStatusDialog] = useState<boolean>(false);
  const [updateOMSPaymentStatusDialog, setUpdateOMSPaymentStatusDialog] = useState<boolean>(false);
  let location = useLocation();
  let oneMealServiceId = location.pathname.split("/")[2];

  const handleSelectCookOrAdminUser = () => {
    dispatch(
      setCustomSelectOpen({
        open: true,
      })
    );
  };

  const getData = (data: any) => {
    setOneMealId(data);
  };

  const handleClickOpen = () => {
    handleSelectCookOrAdminUser();
  };

  const handleAdminUserAfterSelect = (data: any) => {
    setOpenUpdateMealOwner(false);
    setOpenUpdateOneMealAllocator(false);
    setOpenUpdateMealOwner(false);
    if (isForOneMealOwner) {
      handleSelectAdminUserForTrialOwner(data);
    } else if (!isForOneMealOwner) {
      handleSelectAdminUserForTrialAllocator(data);
    }
    return;
  };

  const handleSelectAdminUserForTrialOwner = (data: any) => {
    if (!data) {
      return;
    }
    oneMealService
      .changeOneMealOwner(oneMealId?.id, { ownerId: data?.id })
      .then(() => {
        refresh();
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `One Meal Owner Updated Successfully`,
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
      .catch(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `One Meal Owner was not updated `,
          type: CustomSnackbarTypes.ERROR,
        });
      });
  };

  const handleSelectAdminUserForTrialAllocator = (data: any) => {
    if (!data) {
      return;
    }
    oneMealService
      .changeCookAllocator(oneMealId?.id, { allocatorId: data?.id })
      .then(() => {
        refresh();
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `One Meal Allocator Updated Successfully`,
          type: CustomSnackbarTypes.SUCCESS,
        });
      })
      .catch(() => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: `One Meal Allocator Was not Updated`,
          type: CustomSnackbarTypes.ERROR,
        });
      });
  };

  const handleFixSLC = (recordData: any) => {
    setOpenFixSLC(false);
    oneMealService
      .patchLocality(Number(oneMealServiceId), {
        localityId: recordData?.id,
        cityId: recordData?.cityId,
        areaId: recordData?.areaId,
      })
      .then((res) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "success",
          type: CustomSnackbarTypes.SUCCESS,
        });
        refresh();
      })
      .catch((err) => {
        EventBus.emitEvent(EventBusEventsEnum.SNACKBAR_SHOW, {
          message: "Error",
          type: CustomSnackbarTypes.ERROR,
        });
      });
  };
  return (
    <Show {...props} actions={false}>
      <div>
        <ChangeOmsTimeDialog open={oneMealTimeUpdateDialog} setOpen={setOneMealTimeUpdateDialog} data={selectedData} />
        {refundOneMealModel && <TrialsRefundDialog
          initiatedBy={initiatedBy}
          data={selectedData}
          open={refundOneMealModel}
          callback={() => {
            setRefundOneMealModel(false);
          }}
        />}
        <AdminNotesForOneMeal
          data={selectedData}
          open={openSetAdminNotesDialog}
          setOpen={setOpenSetAdminNotesDialog}
        />
        {openUpdateMealOwner && (
          <ResourceSelectDialog
            name={"AdminUsers"}
            list={AdminUserList}
            onClose={handleAdminUserAfterSelect}
          />
        )}
        {openUpdateMealOwner && (
          <ResourceSelectDialog
            name={"AdminUsers"}
            list={AdminUserList}
            onClose={handleAdminUserAfterSelect}
          />
        )}
        {openUpdateOneMealAllocator && (
          <ResourceSelectDialog
            name={"AdminUsers"}
            list={AdminUserList}
            onClose={handleAdminUserAfterSelect}
          />
        )}
        <PaymentLinkDailog
          data={paymentLinks}
          open={paymentDialog}
          callback={(data) => {
            setPaymentDialog(false);
          }}
        />
        <ApproveTrialsDialog
          initiatedBy={initiatedBy}
          data={selectedData}
          open={approvalModel}
          callback={() => {
            setApprovalModel(false);
          }}
        />
        {rescheduleModel && <RescheduleOneMealDialog
          data={selectedData}
          open={rescheduleModel}
          callback={() => {
            setRescheduleModel(false);
          }}
        />}
        <AssignCookOnOneMealDialog
          data={selectedData}
          open={openAssignCookDialog}
          setOpen={setOpenAssignCookDialog}
          id={oneMealServiceId}
        />
        {cancelTrialModel && <TrialsCancelDialog
          intiatedBy={initiatedBy}
          data={selectedData}
          open={cancelTrialModel}
          callback={() => {
            setCancelTrialModel(false);
          }}
        />}
        {openFixSLC && (
          <ResourceSelectDialog
            name={"Localities"}
            list={LocalityList}
            onClose={handleFixSLC}
          />
        )}
        <UpdateCommentInTrialDialog
          initiatedBy={initiatedBy}
          open={updateCommentDialog}
          setOpen={setUpdateCommentDialog}
        />
        <UpdateOMSStatusDialog
          open={updateOMSStatusDialog}
          setOpen={setUpdateOMSStatusDialog} 
        />
        <UpdateOMSPaymentStatusDialog
          open={updateOMSPaymentStatusDialog}
          setOpen={setUpdateOMSPaymentStatusDialog} 
        />
        {openCookDenyDialog && (
          <CookDenyDialog
            open={openCookDenyDialog}
            setOpen={setOpenCookDenyDialog}
            data={selectedData}
            requestFrom={moduleType.ONE_MEAL_SERVICE}
          />
        )}
        {requestCookConveyanceDialog && (
          <RequestCookConveyanceDialog
            open={requestCookConveyanceDialog}
            setOpen={setRequestCookConveyanceDialog}
            data={selectedData}
          />
        )}
        <Show {...props} actions={false}>
          <ActionButtons
            handleClickOpen={handleClickOpen}
            setCancelTrialModel={setCancelTrialModel}
            setRefundOneMealModel={setRefundOneMealModel}
            setRescheduleModel={setRescheduleModel}
            setApprovalModel={setApprovalModel}
            setSelectedData={setSelectedData}
            getData={getData}
            setIsForOneMealOwner={setIsForOneMealOwner}
            setOpenSetAdminNotesDialog={setOpenSetAdminNotesDialog}
            setOpenAssignCookDialog={setOpenAssignCookDialog}
            setOpenFixSLC={setOpenFixSLC}
            setOpenUpdateOneMealAllocator={setOpenUpdateOneMealAllocator}
            setOpenUpdateMealOwner={setOpenUpdateMealOwner}
            setPaymentDialog={setPaymentDialog}
            setPaymentLinks={setPaymentLinks}
            setUpdateCommentDialog={setUpdateCommentDialog}
            setUpdateOMSStatusDialog={setUpdateOMSStatusDialog}
            setUpdateOMSPaymentStatusDialog={setUpdateOMSPaymentStatusDialog}
            setInitiatedBy={setInitiatedBy}
            setOpenCookDenyDialog={setOpenCookDenyDialog}
            setOneMealTimeUpdateDialog={setOneMealTimeUpdateDialog}
            setRequestCookConveyanceDialog={setRequestCookConveyanceDialog}
          />
          <OneMealServiceTabs />
        </Show>
      </div>
    </Show>
  );
};

export default OneMealServiceShow;
