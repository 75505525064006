import { Button } from "@mui/material";
import { Dispatch, useEffect, useState } from "react";
import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  List,
  RaRecord,
  ReferenceField,
  SelectInput,
  TextField,
  useListContext,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { HouseReviewStatus } from "../../enums/HouseReviewStatus";
import ApproveHouseReview from "../../interfaces/HouseReview/ApproveHouseReview";
import HouseReviewService from "../../services/HouseReviewService";
import ApproveHouseReviewDialog from "../Common/ApproveHouseReviewDialog";
import ConfirmationDialog from "../Common/ConfirmationDialog";
import RejectHouseReviewDialog from "../Common/RejectHouseReviewDialog";
import CityService from "../../services/CityService";
import CityResponse from "../../interfaces/City/CityResponse";
import AreaService from "../../services/AreaService";

const houseReviewService = new HouseReviewService();
const cityService = new CityService();
const areaService = new AreaService();

interface InputProps {
  setRejectHouseDialog: Dispatch<React.SetStateAction<boolean>>;
  setSelectedHouseReview: Dispatch<React.SetStateAction<RaRecord | undefined>>;
  setApproveDialog: Dispatch<React.SetStateAction<boolean>>;
}

// Filter Component
const HouseReviewFilter = (props:any) => {
  const { setFilters, filterValues } = useListContext(); // Correctly use the context
  const [cities, setCities] = useState<CityResponse[]>([]);
  const [areas, setAreas] = useState<any>([]);

  // Handle City Change
  const handleCityChange = (event: any) => {
    const newCityId = event.target.value;
    delete filterValues?.areaId
    setTimeout(()=>{
      setFilters({...filterValues,cityId:newCityId},[],false);
    },1000)
  };

  // Fetch cities on component mount
  useEffect(() => {
    cityService.fetchAllCities(100, 0, {}, {}).then((res) => {
      setCities(res?.data || []);
    });
  }, []);

  // Fetch areas when city changes
  useEffect(() => {
    if (filterValues.cityId) {
      areaService
        .fetchAllAreas(1000, 0, { cityId: filterValues.cityId }, { field: "name", order: "ASC" })
        .then((res) => {
          setAreas(res?.data || []);
        });
    } else {
      setAreas([]); // Clear areas if no city is selected
    }
  }, [filterValues.cityId]);

  return (
    <Filter {...props}>
      <SelectInput
        label="City"
        source="cityId"
        alwaysOn
        emptyText="Clear Filter"
        choices={cities.map((eachCity) => ({
          id: eachCity?.id,
          name: eachCity?.name,
        }))}
        onChange={handleCityChange}
      />
      <AutocompleteInput
        label="Area"
        source="areaId"
        choices={[...areas.map((eachArea: any) => ({
          id: eachArea?.id,
          name: eachArea?.name,
        })),{
          id: "none",
          name: "none",
        }]}
        emptyText="Clear Filter"
        alwaysOn
      />
      <SelectInput
        label="Status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={Object.values(HouseReviewStatus).map((key) => ({
          id: key,
          name: key,
        }))}
      />
    </Filter>
  );
};

// Buttons Component
const CTAButtons = (props: InputProps) => {
  const record = useRecordContext();

  return (
    <div style={{ display: "flex", gap: 10 }}>
      {record.status === HouseReviewStatus.PENDING && (
        <>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => {
              props.setApproveDialog(true);
              props.setSelectedHouseReview(record);
            }}
            disableElevation
          >
            Approve
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => {
              props.setRejectHouseDialog(true);
              props.setSelectedHouseReview(record);
            }}
            disableElevation
          >
            Reject
          </Button>
        </>
      )}
    </div>
  );
};

// Main List Component
const HouseReviewList = () => {
  const [showApproveDialog, setShowApproveDialog] = useState(false);
  const [selectedHouseReview, setSelectedHouseReview] = useState<RaRecord>();
  const [rejectHouseDialog, setRejectHouseDialog] = useState(false);
  const refresh = useRefresh();

  const handleApproveHouse = (data: ApproveHouseReview) => {
    if (selectedHouseReview) {
      houseReviewService
        .approve(selectedHouseReview.id as number, {
          localityId: data.localityId,
          cityId: data.cityId,
          areaId: data.areaId,
        })
        .then(refresh);
    }
  };

  return (
    <div>
      <ApproveHouseReviewDialog
        open={showApproveDialog}
        setOpen={setShowApproveDialog}
        callback={handleApproveHouse}
      />

      {rejectHouseDialog && (
        <RejectHouseReviewDialog
          data={selectedHouseReview as any}
          open={rejectHouseDialog}
          setOpen={setRejectHouseDialog}
        />
      )}

      <ConfirmationDialog />
      <List filters={<HouseReviewFilter />} sort={{ field: "id", order: "DESC" }}>
        <Datagrid rowClick="show" bulkActionButtons={false}>
          <TextField source="id" />
          <ReferenceField source="houseId" link="show" reference="Houses">
            <TextField source="id" />
          </ReferenceField>
          <TextField source="status" />
          <ReferenceField source="cityId" link="show" reference="Cities">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="areaId" link="show" reference="Areas">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="localityId" link="show" reference="Localities">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="otherSlc" />
          <DateField source="createdAt" />
          <DateField source="updatedAt" />
          <CTAButtons
            setRejectHouseDialog={setRejectHouseDialog}
            setApproveDialog={setShowApproveDialog}
            setSelectedHouseReview={setSelectedHouseReview}
          />
        </Datagrid>
      </List>
    </div>
  );
};

export default HouseReviewList;
