import {
    Button,
    Datagrid,
    DateField,
    DateInput,
    EditButton,
    ExportButton,
    FunctionField,
    List,
    SelectInput,
    ShowButton,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    useRecordContext,
} from "react-admin";
import CustomReferenceField from "../Common/CustomReferenceField";
import ChefForPartStatusEnum from "../../enums/ChefForPartStatusEnum";
import {PaymentStatusEnum} from "../../enums/PaymentStatusEnum";
import FilterListOffIcon from "@mui/icons-material/FilterListOff";
import {TimeEnum} from "../../enums/TimeEnum";
import CallButton from "../Common/CallButton/CallButton";
import { UserType } from "../../enums/common";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import {useNavigate} from "react-router-dom";

const filters = [
    <SelectInput
        label="Filter by status"
        source="status"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(ChefForPartStatusEnum)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <SelectInput
        label="Token Amount Payment Status"
        source="tokenAmountPaymentStatus"
        alwaysOn
        emptyText="Clear Filter"
        sx={{width: "180px"}}
        choices={
            Object.values(PaymentStatusEnum)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <SelectInput
        label="Remaining Amount Payment Status"
        source="remainingAmountPaymentStatus"
        alwaysOn
        emptyText="Clear Filter"
        sx={{width: "180px"}}
        choices={
            Object.values(PaymentStatusEnum)
                .map(key => {
                    return {
                        id: key,
                        name: key
                    }
                })
        }
    />,
    <DateInput label="Date" source="date" alwaysOn />,
    <TextInput label="Customer Phone" source="customerPhone" alwaysOn />,
    <TextInput label="City" source="cityName" alwaysOn />,
    <SelectInput
        label="Time"
        source="time"
        alwaysOn
        emptyText="Clear Filter"
        choices={
            Object.values(TimeEnum)
                .map(value => {
                    return {
                        id: value,
                        name: value
                    }
                })
        }
    />,

    <DateInput label="Created At Start Date" source="startDate" alwaysOn />,
    <DateInput label="Created At End Date" source="endDate" alwaysOn />,
    // <SelectInput
    //     label="Filter by Cook Allocation"
    //     source="cookAllocationStatus"
    //     alwaysOn
    //     emptyText="Clear Filter"
    //     choices={
    //         Object.values(CookAllocationStatus)
    //             .map(key => {
    //                 return {
    //                     id: key,
    //                     name: key
    //                 }
    //             })
    //     }
    // />,
    // <TextInput label="Created By" source="createdBy" alwaysOn/>
];

const EditButtonView = () => {
    const record = useRecordContext();
    if (![ChefForPartStatusEnum.FULFILLED, ChefForPartStatusEnum.CANCELLED, ChefForPartStatusEnum.RESCHEDULED].includes(record.status)) {
        return (
            <EditButton />
        )
    }
    return null
}

const ListActions = (props: any) => {
    const { setFilters } = useListContext();
    const resetFilter = () => {
      setFilters({}, [], false);
    };
    const navigate = useNavigate();
  
    return (
      <TopToolbar>
        <Button
          variant="text"
          size={"small"}
          onClick={resetFilter}
          startIcon={<FilterListOffIcon />}
          label="Clear filters"
        />
        <Button
          variant="text"
          size={"small"}
          onClick={()=>{
            navigate('/ChefForParty/create')
          }}
          startIcon={<ControlPointIcon />}
          label="Create"
        />
        <ExportButton/>
      </TopToolbar>
    );
};

const ChefForPartList = (props: any) => {
    return (
        <div>
            <List {...props} filters={filters} sort={{ field: "id", order: "DESC" }} actions={<ListActions/>}>
                <Datagrid
                    bulkActionButtons={false}
                >
                    <TextField source='id' />
                    <DateField showDate source='date' />
                    <TextField source='time' />
                    <CustomReferenceField label="House" listName={"Houses"} source={"houseId"} />
                    <TextField source='customerName' />
                    {/* <TextField source='customerPhone' /> */}
                    <FunctionField label="Customer Phone" render={(record: any) => 
                        <CallButton toPhoneNumber={record.customerPhone} userType={UserType.CUSTOMER}/>
                    } />
                    <TextField source='cityName' />
                    <TextField source='noOfAdults' />
                    <TextField source='noOfChildren' />
                    <TextField source='noOfDishes' />
                    <TextField source='totalAmount' />
                    <TextField source='tokenAmountPaymentStatus' />
                    <TextField source='remainingAmountPaymentStatus' />
                    <TextField source='status' />
                    <DateField showTime source='createdAt'  />
                    <FunctionField label="" render={()=><EditButtonView/>}/>
                    <ShowButton />
                </Datagrid>
            </List>
        </div>
    )
}

export default ChefForPartList;
